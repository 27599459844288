import React from "react"

import SEO from "../components/seo"

const Impressum = () => (
    <div className={"content"}>
        <SEO title="Impressum" />
        <h1>Impressum</h1>

        <p className={"long"}>
            Max Cuap <br />
        c/o Frachtraum<br />
        Seestrasse 14<br />
        3600 Thun
    </p>
    </div>
)

export default Impressum